import React from "react";


const Infobanner = () => {
    return (
        <div className="textbanner-container">
            <div className="textbanner-title">
                <span className="textbanner-title">En Motidigital, nos obsesiona la innovación en Expresionismo Visual</span>
            </div>
            <div className="textbanner-text">
                <span className="textbanner-text">Somos expertos en ofrecerte infinitas posibilidades creativas para que tu marca
                    <br></br>y producto impacten las exigencias del mercado actual.</span>
            </div>
            <div className="textbanner-footer">
                <span className="textbanner-footer">La Diferenciación es la clave de tu Éxito.</span>
            </div>
        </div>
    );
};


export default Infobanner;
