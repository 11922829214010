import React, { useState } from "react";

const UsSection = () => {
    const philosophies = [
        {
            title: 'Rush',
            icon: 'assets/img/home-page/Iconografia Nosotros-01.png',
            description: 'Todo es para hoy',
        }, {
            title: 'Pasión',
            icon: 'assets/img/home-page/Iconografia Nosotros-06.png',
            description: 'Estoy aquí porque me encanta',
        },
        
        {
            title: 'Alegría',
            icon: 'assets/img/home-page/Iconografia Nosotros-07.png',
            description: 'Encuentro satisfacción en los retos ',
        },
        {
            title: 'Adaptabilidad',
            icon: 'assets/img/home-page/Iconografia Nosotros-04.png',
            description: 'Lo único seguro es el cambio',
        },
        {
            title: 'Jugando en equipo',
            icon: 'assets/img/home-page/Iconografia Nosotros-08.png',
            description: 'Tu resultado es mi resultado',
        }
    ]

    const values = [
        {
            title: 'Misión',
            icon: 'assets/img/home-page/Iconografia Nosotros-03.png',
            description: 'Ser una empresa altamente rentable y sustentable con colaboradores súper comprometidos, apasionados y bien remunerados que presumen lo que hacen. Con clientes fanáticos y felices de nuestro trabajo, siempre en alianza con nuestros proveedores.',
        },
        {
            title: 'Propósito',
            icon: 'assets/img/home-page/Iconografia Nosotros-02.png',
            description: 'Ser un equipo imparable de comunicación visual para personas y marcas en evolución constante',
        },
        {
            title: 'Principios',
            icon: 'assets/img/home-page/Iconografia Nosotros-05.png',
            description: 'Todos los que integramos Moti Digital estamos comprometidos construyendo una empresa socialmente responsable y sustentable, a través de nuestros valores empresariales, nuestro código de conducta: bajo los cuales nos relacionamos y conducimos la empresa, buscando además del crecimiento de la empresa apoyar el desarrollo profesional y personal de nuestros colaboradores, mejorando su calidad de vida y el de sus familias, contribuyendo en las necesidades sociales de nuestro país.',
        },
        {
            title: 'Política de calidad ',
            icon: 'assets/img/home-page/Iconografia calidad-09.png',
            description: 'En Moti nos enfocamos en garantizar la satisfacción de nuestros clientes a través de nuestra cultura de servicio extraordinario y operación eficaz, trabajando bajo nuestro sistema de gestión integral, cuidando del medio ambiente, la seguridad y salud de nuestros colaboradores y fomentando la mejora continua de nuestros procesos.',
        },
    ];
    // Usamos dos estados separados para gestionar qué elementos están abiertos en filosofía y valores
    const [openPhilosophies, setOpenPhilosophies] = useState(Array(philosophies.length).fill(false));
    const [openValues, setOpenValues] = useState(Array(values.length).fill(false));

    // Función para alternar la apertura de un elemento por índice en filosofía
    const togglePhilosophyItem = (index) => {
        const newOpenPhilosophies = [...openPhilosophies];
        newOpenPhilosophies[index] = !newOpenPhilosophies[index];
        setOpenPhilosophies(newOpenPhilosophies);
    };

    // Función para alternar la apertura de un elemento por índice en valores
    const toggleValuesItem = (index) => {
        const newOpenValues = [...openValues];
        newOpenValues[index] = !newOpenValues[index];
        setOpenValues(newOpenValues);
    };

    return (
        <div className="textbanner-container values-main" id="Us">
            <div className="main-title-container">
                <div className="us-title">
                    <span className="head_style_2">Nosotros</span>
                </div>
                <div className="us-text">
                    <span className="us-text">Filosofía</span>
                    <span className="us-text">Valores</span>
                </div>
                <div className="text-divider-us"></div>
            </div>
            <div className="values-wrap">
                <div className="values-div">
                    <div>
                        <ul className="list_style centered" id="darkBtn2">
                            {values.map((tech, index) => (
                                <div className="values-container" key={tech.title}>
                                    <input
                                        type="checkbox"
                                        id={`collapse-values-${index}`} // Usamos el índice como identificador para valores
                                        checked={openValues[index]} // Verificamos si está abierto en valores
                                        onChange={() => toggleValuesItem(index)} // Utilizamos la función para valores
                                    />
                                    <label className="collapse" htmlFor={`collapse-values-${index}`}>
                                        <img src={tech.icon} className="icon-values" />
                                        <span className="title-values">{tech.title}</span>
                                        <div className="iconPlus">
                                            <span className="plus"></span>
                                            <span className="minus"></span>
                                        </div>
                                        <div className="details">{tech.description}</div>
                                    </label>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="values-div">
                    <div>
                        <ul className="list_style centered" id="darkBtn2">
                            {philosophies.map((ph, index) => (
                                <div className="philos-container" key={ph.title}>
                                    <input
                                        type="checkbox"
                                        id={`collapse-philosophies-${index}`} // Usamos el índice como identificador para filosofía
                                        checked={openPhilosophies[index]} // Verificamos si está abierto en filosofía
                                        onChange={() => togglePhilosophyItem(index)} // Utilizamos la función para filosofía
                                    />
                                    <label className="collapse" htmlFor={`collapse-philosophies-${index}`}>
                                        <img src={ph.icon} alt="icon-values" className="icon-values" />
                                        <span className="title-values">{ph.title}</span>
                                        <div className="iconPlus">
                                            <span className="plus"></span>
                                            <span className="minus"></span>
                                        </div>
                                        <div className="details">{ph.description}</div>
                                    </label>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsSection;