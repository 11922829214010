import React from 'react';

const TechSection = () => {
  const technologies = [
    {
      title: 'Impresión UV | Durst',
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias UV.mp4',
      text: `
      A este tipo de impresión se le conoce UV por el tipo de secado con luz ultravioleta. Esta tecnología nos brinda la posibilidad de imprimir materiales de gran formato, flexibles y rígidos de gran calidad que soportan la exposición a intemperies. Podemos imprimir frente y vuelta en materiales rígidos y flexibles, desde 1, 3 y 5 capas de impresión y saturación de tinta modo: backlit.
      `,
    },
    {
      title: 'Impresión Ecológica (Látex) | HP',
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias LATEX.mp4',
      text: 'La impresión ecológica brinda colores intensos a alta velocidad y contrastes impresionantes usando negros puros, textos nítidos de larga duración que soportan las inclemencias del tiempo. Además, tiene una gran variedad de aplicaciones para interiores y exteriores que abarcan papel para póster/carteles/textiles, lona, papel de pared y vinilo logrando una permanencia que dura hasta tres años sin laminación y son resistentes a rasguños, borrones y al agua en varios tipos de soporte. Las impresiones HP Látex son reciclables, retornables y no presentan peligro, Están diseñadas para ser amigables con el medio ambiente.',
    },
    {
      title: 'Tecnología de Sublimación | Durst',
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias SUBLIMACION DURST.mp4',
      text: `
      La tecnología de sublimación garantiza una excelente consistencia de color, así como una nitidez extrema de los detalles en las imágenes y el texto.  Combinando eficiencia, calidad y productividad al más alto nivel. Un ejemplo son las telas decorativas con colores fuertes y brillantes y una reproducción exacta de los detalles
      Con más de 100 distintas telas, lonas y viniles ofrece posibilidades innovadoras para la creación de POP, branding, moda y diseño. Otro beneficio de la sublimación es que gracias al uso de tintas inodoras a base de agua la hacen amigable con el medio ambiente.
      `,
    },
    {
      title: 'Impresión OFFSET Digital | Índigo',
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias INDIGO.mp4',
      text: `
      Versatilidad para imprimir con selección de color y tintas directas. El secreto radica en la transferencia térmica, las impresiones salen de la prensa secas y listas para su acabado. De este proceso resultan imágenes de alta calidad y una capa fina y flexible de tinta.
      El máximo de impresión es de 74 x 51 cm, imprime 4,600 hojas a color por hora, dato variable en texto e imágenes brindando personalización de impresos, reducción de costos, tiempos de entrega reducidos.  Además, Eco-friendly evitando la generación de residuos
      `,
    },
    {
      title: 'Impresión de Etiquetas | Índigo',
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias ETIQUETAS.mp4',
      text: 'La prensa digital HP Índigo, es referente en calidad y producción de etiquetas, mangas termoencogibles, empaque flexibles y estuches de cartulina. Brinda valor agregado a los trabajos con acabados especiales y una amplia variedad de tintas, mayor eficiencia de producción personalización de etiquetas en el mismo proceso y diseños exclusivos con la más alta resolución y calidad.',
    },
    {
      title: 'Impresión Fotográfica | Kodak Professional LED',
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias FOTOGRAFIA.mp4',
      text: '',
    },
  ];

  return (
    <section className="section dark_section digital_design" id="Tech">
      <div className="animation_wrap">
        <div className="title_section reveal active">
          <span className="techtext-title">La innovación tecnológica en impresión y acabados es nuestro distintivo.</span>
        </div>
        <div className="title_section reveal active">
          <span className="head_style_2">Tecnologías de Impresión</span>
        </div>
        <div className="details_section reveal active">
          <div>
            <ul className="list_style centered" id="darkBtn2">
              {technologies.map((tech) => (
                <li key={tech.title}>{tech.title}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container tech-container">
        {technologies.map((tech, index) => (
          <div key={tech.title} className={`video-flex reveal active ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
            <div className="video-tech">
              <div className="video-box">
                <video className='video-tech' loop autoPlay muted playsInline loading="lazy" preload="auto">
                  <source src={tech.videoSrc} type="video/mp4" />
                  Your web browser doesn't support <code>video</code> element.
                  <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                    Install Google Chrome
                  </a>
                </video>
              </div>
            </div>
            <div className={`text-video${index % 2 === 0 ? '' : '-reverse'}`}>
              <h3 className={`tech-tittle${index % 2 === 0 ? '' : '-reverse'}`}>{tech.title}</h3>
              <p className="parrafo">{tech.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TechSection;
