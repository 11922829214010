import React from "react";

const EcommerceBanner = () => {
  const ecommerceImages = [
    {
      src: "assets/img/home-page/motistore.png",
      alt: "icon-commerce",
      link: "https://www.motistore.com/",
    },
    {
      src: "assets/img/home-page/motifabrics.png",
      alt: "icon-commerce",
      link: "https://motifabrics.com/",
    },
    {
      src: "assets/img/home-page/cart-click.png",
      alt: "icon-commerce",
    },
  ];

  return (
    <div className="textbanner-container">
      <div className="textbanner-title">
        <span className="head_style_2">Tienda en línea</span>
      </div>
      <div className="textbanner-text">
        <span className="textbanner-text">Personalizar tus ideas nunca fue tan sencillo</span>
      </div>
      <div className="ecommerce-icons">
        {ecommerceImages.map((image, index) => (
          <a
            key={index}
            href={image.link} 
            target="_blank"
            rel="noopener noreferrer"
            className="link-icon"
          >
            <img src={image.src} alt={image.alt} className="icon-commerce" />
          </a>
        ))}
      </div>
      <div className="banner">
        <img src="assets/img/home-page/banner-ecommerce-pc.png" alt="Banner" />
      </div>
    </div>
  );
};

export default EcommerceBanner;
