import React, { useEffect } from 'react';


const TextBanner = () => {
  const KeyWords = [
    { title: 'Comunicación Visual' },
    { title: 'Interiorismo Comercial' },
    { title: 'Punto de Venta' },
    { title: 'Impresión Digital' },
    { title: 'Tecnología Scodix' },
    { title: 'Innovación' },
    { title: 'Creatividad' },
    { title: 'Experiencia de Marca' },
    { title: 'Empaques' },
    { title: 'Etiquetas' },
    { title: 'Retail' },
    { title: 'Diseño' },
    { title: 'Merchandising' },
    { title: 'Producción' }
  ];
  
  useEffect(() => {
    // Ejecuta este código cuando el componente se monte
    const lis = document.querySelectorAll('#structure > div');
    let currentHighlight = 0;
    const N = 4; // Intervalo en segundos

    const interval = setInterval(function () {
      lis[currentHighlight].classList.remove('active');
      currentHighlight = (currentHighlight + 1) % lis.length;
      lis[currentHighlight].classList.add('active');
    }, N * 300);

    // Detén el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, []); // El segundo argumento [] garantiza que se ejecute solo una vez

  return (
    <div className="its-all-in-the-movement wrapper" id="inicio">
      <div className="structure middle-holder">
        <div className="title-banner-text"><b>Expertos</b> en:</div>
      </div>
      <div id="structure" className="structure">
        {KeyWords.map((word, index) => (
         index === 0 ? (
          // Lógica si el índice es 0
           <div className="subtitle-banner-text moving-title inactive active" key={index}>{word.title}</div>
        ) : (
          // Lógica para otros índices
          <div className="subtitle-banner-text moving-title inactive" key={index}>{word.title}</div>
        )
        ))}
      </div>
      <div className="text-divider"></div>
    </div>
  );
};

const HomeBanner = () => {
  return (
    // Banner section with video background
    <div className="banner section video-banner">
      <TextBanner />
      {/* Video element with auto-play, muted, and loop attributes */}
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }} className="activate pc">
        <iframe
          title="Background Video"
          src="https://player.vimeo.com/video/880356439?h=69d2279d34&autoplay=1&loop=1&controls=0&muted=1"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
};

export default HomeBanner;


