import React, { useEffect, useState } from 'react';

const Counter = ({ count, counttext, showPlus }) => {
  const target = +count;
  const speed = 200;
  const increment = target / speed;
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentCount < target) {
        setCurrentCount(prevCount => prevCount + increment);
      }
    }, 1000 / speed);

    return () => clearTimeout(timer);
  }, [currentCount, target, increment]);

  return (
    <li className="count" data-count={count}>
      <div>
        {showPlus && <span>+</span>}
        <span className="show-count">{Math.ceil(currentCount)}</span>
      </div>
      <span className="text-count" dangerouslySetInnerHTML={{ __html: counttext }}></span>
    </li>
  );
};

const CountBanner = () => {
  const [animationKey, setAnimationKey] = useState(0);

  const updateCount = () => {
    const tiempoAleatorio = Math.floor(Math.random() * (10000 - 5000 + 1) + 5000);
    setTimeout(() => {
      setAnimationKey(prevKey => prevKey + 1);
    }, tiempoAleatorio);
  };

  useEffect(() => {
    const intervalId = setInterval(updateCount, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <ImageBanner />
      <CounterNumbers key={animationKey} />
    </div>
  );
};

const ImageBanner = () => (
  <div className="banner">
    <img src="assets/img/home-page/img-banner1.png" alt="Banner" />
  </div>
);

const CounterNumbers = () => (
  <div>
    <ul className="counter-list">
      <Counter count={19} counttext="Años de experiencia" />
      <Counter count={2500} counttext="Proyectos en<br /> América" showPlus={true} />
      <Counter count={1000} counttext="clientes" showPlus={true} />
      <Counter count={300} counttext="colaboradores" showPlus={true} />
    </ul>
  </div>
);

export default CountBanner;
