import React from "react";

const Card = ({ type, imgSrc, title, link }) => (
    <a href={link} rel="noopener noreferrer" className="card-link">
        <div className="card" type={type}>
            <div className="img-overlay"></div>
            <span className="product-card-title">{title}</span>
            <img src={imgSrc} alt={title} />
        </div>
    </a>
);

const cardsData = [
    {
        imgSrc: 'assets/img/home-page/categories/Brand experience.png',
        title: 'Brand experience',
        link: '/#/brand',
    },
    {
        imgSrc: 'assets/img/home-page/categories/Visual Merch.png',
        title: 'Visual Merch',
        link: '/#/visual',
    },
    {
        imgSrc: 'assets/img/home-page/categories/Display.png',
        title: 'Display',
        link: '/#/display',
    },
    {
        imgSrc: 'assets/img/home-page/categories/Packaging.png',
        title: 'Packaging',
        link: '/#/packaging',
    },
    {
        imgSrc: 'assets/img/home-page/categories/Etiquetas.png',
        title: 'Etiquetas',
        link: '/#/labels',
    },
    {
        imgSrc: 'assets/img/home-page/categories/Institucional.png',
        title: 'Institucional',
        link: '/#/institutional',
    },
];

const ProductSection = () => {
    return (
        <div className="product-container" id="products">
            <div className="product-title">
                <span>Productos y servicios</span>
            </div>
            <div className="product-text">
                <span className="product-text">Vive la experiencia Moti</span>
            </div>
            <div className="product-links">
                {cardsData.map((data, index) => (
                    <Card key={index} {...data} />
                ))}
            </div>
        </div>
    );
};

export default ProductSection;
